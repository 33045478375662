import React from 'react';
import { Link } from 'gatsby';
import * as ROUTES from '../../constants/routes';
import { makeStyles } from '@material-ui/styles';
import { Theme as AugmentedTheme, Typography, Button } from '@material-ui/core';
import { LocalizationKey } from '../../localization/LocalizationKey';
import { Localization } from '../../localization/Localization';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  link: {
    textDecoration: 'none'
  },
  button: {
    textTransform: 'none'
  },
  linkTextColor: {
    color: theme.colors.oxfordBlue06
  }
}));

export const PasswordForgetLink = () => {
  const styles = useStyles();
  return (
    <p>
      <Link to={ROUTES.PASSWORD_FORGET} className={styles.link}>
        <Button className={styles.button}>
          <Typography className={styles.linkTextColor} variant="h6">
            {Localization.getInst().localizedString(LocalizationKey.forgotPasswordQuestion)}
          </Typography>
        </Button>
      </Link>
    </p>
  );
};
