import React, { Fragment, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme as AugmentedTheme, Typography } from '@material-ui/core';
import { SignInForm } from '../components/SignIn/Form';
import { PasswordForgetLink } from '../components/PasswordForget/Link';
import { Card, CardContent } from '@material-ui/core';
import { LocalizationKey } from '../localization/LocalizationKey';
import { Localization } from '../localization/Localization';
import { SessionContext } from '../components/Session/Context';
import { AuthUser } from '../types/modelsFirebase';
import { navigate } from 'gatsby';
import * as ROUTES from '../constants/routes';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: theme.remSpacing(12),
    textAlign: 'center'
  },
  card: {
    width: 360
  },
  signInTitle: {
    color: theme.colors.oxfordBlue06
  }
}));

const SignInPage = () => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <CardContent>
          <Typography variant="h1" className={styles.signInTitle} gutterBottom>
            {Localization.getInst().localizedString(LocalizationKey.signInTitle)}
          </Typography>
          <SignInForm />
          <PasswordForgetLink />
        </CardContent>
      </Card>
    </div>
  );
};

const RootPage = () => {
  const authUser = useContext(SessionContext);

  const isLoggedIn = (authUser: AuthUser | null) => {
    return authUser && authUser.user;
  };

  const canRenderSignIn = () => {
    return authUser && authUser.authChecked;
  };

  useEffect(() => {
    if (isLoggedIn(authUser)) {
      navigate(ROUTES.HOME);
    }
  }, [authUser]);

  return <Fragment>{canRenderSignIn() && <SignInPage />}</Fragment>;
};

export default () => <RootPage />;
